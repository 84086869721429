import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';
import { BehaviorSubject, Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.getUserFromLocalStorage());
  public user$ = this._userSubject.asObservable();


  private getUserFromLocalStorage() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  //url = "http://toponreviews.com/api";
  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  login(
    req
  ) {
    return this.http.post(
      `${this.url}/login`,
      req
    );
  }

  registration(
    req
  ) {
    return this.http.post(
      `${this.url}/register`,
      req
    );
  }

  set user(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get user() {
    return JSON.parse(localStorage.getItem('user'));
  }

  get isLogedin() {
    return this.user && this.user.email ? true : false;
  }

  forgotpassword(
    req
  ) {
    return this.http.post(
      `${this.url}/send_forgot_password_mail`,
      req
    );
  }

  logout() {
    return this.http.get(`${this.url}/logout`);
  }

  getUserById(id){
    return this.http.get(`${this.url}/get_user_by_id/` + id);
  }

  updateUser(
    req
  ) {
    return this.http.post(
      `${this.url}/update_user`,
      req
    );
  }

  changePassword( req ) {
    //return this.http.post(`${this.url}/changepassword`, req);
    return this.http.post(`${this.url}/changepassword`, req, this.httpOptions);
  }

  set userToken(token) {
    window.localStorage.setItem('token', token);
  }

  get userToken() {
    return window.localStorage.getItem('token');
  }

  checkEmailVarification(id, token){
    return this.http.get(`${this.url}/email/verify/`+ id+'/'+token);
  }

  getUsers(){
    return this.http.get(`${this.url}/get_all_users`, this.httpOptions);
  }

  getUsersWithPage(page,search_value,industry_id){
    return this.http.get(`${this.url}/get_all_users_page/`+page+`?search_value=`+search_value+`&industry_id=`+industry_id, this.httpOptions);
  }

  getCoachesWithPage(page,search_value,industry_id){
    return this.http.get(`${this.url}/get_all_coaches_page/`+page+`?search_value=`+search_value+`&industry_id=`+industry_id, this.httpOptions);
  }

  getAllIndustry(){
    return this.http.get(`${this.url}/get_all_industries`, this.httpOptions);
  }

  resetPassword(req) {
    return this.http.post(`${this.url}/reset_password`, req);
  }

  addOrUpdateIndustry(req) {
    return this.http.post(`${this.url}/store_industry`, req, this.httpOptions);
  }

  getAllCountry(){
    return this.http.get(`${this.url}/get_all_countries`, this.httpOptions);
  }

  getStates(countryId: number){
    return this.http.get(`${this.url}/get_all_states/`+ countryId, this.httpOptions);
  }

  saveCountry(req){
    return this.http.post(`${this.url}/store_country`, req, this.httpOptions);
  }

  saveHeadline(req){
    return this.http.post(`${this.url}/store_summary`, req, this.httpOptions);
  }
  saveUsername(req: any) {
    return this.http.post(`${this.url}/edit_business_name`, req).pipe(
      tap((res: any) => {
        if (res.status) {
          // Save updated user in localStorage
          localStorage.setItem('user', JSON.stringify(res.user));
  
          // Emit updated user to subscribers (like sidebar)
          this._userSubject.next(res.user);
        }
      })
    );
  }

  saveFullExp(req){
    return this.http.post(`${this.url}/store_full_experience`, req, this.httpOptions);
  }

  saveSocial(req){
    return this.http.post(`${this.url}/store_social_details`, req, this.httpOptions);
  }

  saveExperience(req){
    return this.http.post(`${this.url}/store_experience`, req, this.httpOptions);
  }
  saveBusiness(req){
    return this.http.post(`${this.url}/store_business`, req, this.httpOptions);
  }

  getExperience(){
    return this.http.get(`${this.url}/get_all_experience`, this.httpOptions).pipe(catchError(this.handleError));
  }
  getBusiness(id){
    return this.http.get(`${this.url}/get_user_by_id/` +id, this.httpOptions).pipe(catchError(this.handleError));
  }
 
  
  deleteExperience(id){
    return this.http.get(`${this.url}/delete_experience/`+id, this.httpOptions);
  }

  deleteBusiness(id) {
    return this.http.get(`${this.url}/delete_business/` +id, this.httpOptions);
  }
  
  saveEducation(req){
    return this.http.post(`${this.url}/store_education`, req, this.httpOptions);
  }

  getEducation(){
    return this.http.get(`${this.url}/get_all_education`, this.httpOptions);
  }

  deleteEducation(id){
    return this.http.get(`${this.url}/delete_education/`+id, this.httpOptions);
  }

  deleteSummary(){
    return this.http.get(`${this.url}/delete_summary`, this.httpOptions);
  }

  getAllCertificate(){
    return this.http.get(`${this.url}/get_all_certificate`, this.httpOptions);
  }

  saveCertificate(req){
    return this.http.post(`${this.url}/store_certificate`, req, this.httpOptions);
  }

  deleteCertificate(id){
    return this.http.get(`${this.url}/delete_certificate/`+id, this.httpOptions);
  }

  getAllAchievement(){
    return this.http.get(`${this.url}/get_all_achievement`, this.httpOptions);
  }

  saveAchievement(req){
    return this.http.post(`${this.url}/store_achievement`, req, this.httpOptions);
  }

  deleteAchievement(id){
    return this.http.get(`${this.url}/delete_achievement/`+id, this.httpOptions);
  }

  updateAchievement(req){
    return this.http.post(`${this.url}/update_achievement`, req, this.httpOptions);
  }

  updateCertificate(req){
    return this.http.post(`${this.url}/update_certificate`, req, this.httpOptions);
  }

  updateBusiness(req){
    return this.http.post(`${this.url}/store_business`, req, this.httpOptions);
  }
  updateExperience(req){
    return this.http.post(`${this.url}/update_experience`, req, this.httpOptions);
  }
  updateEducation(req){
    return this.http.post(`${this.url}/update_education`, req, this.httpOptions);
  }

  updateUserRole(req){
    return this.http.post(`${this.url}/update_user_role`, req, this.httpOptions);
  }

  getAllUserTypeUser(){
    return this.http.get(`${this.url}/get_all_users_type_user`, this.httpOptions);
  }

  createUserRating(req){
    return this.http.post(`${this.url}/ratings/store`, req, this.httpOptions);
  }

  getAllTool(){
    return this.http.get(`${this.url}/ratings/get_all_tool`, this.httpOptions);
  }

  getSingleTool(id){
    return this.http.get(`${this.url}/ratings/get_signle_tool/`+id, this.httpOptions);
  }

  getAllQuestions(){
    return this.http.get(`${this.url}/ratings/get_all_question`, this.httpOptions);
  }

  deleteUserTool(id){
    return this.http.get(`${this.url}/ratings/delete/`+id, this.httpOptions);
  }

  createUserRating2(req){
    return this.http.post(`${this.url}/ratings/store_2_tool`, req, this.httpOptions);
  }

  getAllQuestionsByType(id){
    return this.http.get(`${this.url}/ratings/get_all_question/`+id, this.httpOptions);
  }

  createUserRating4(req){
    return this.http.post(`${this.url}/ratings/store_4_tool`, req, this.httpOptions);
  }

  createUserRating3(req){
    return this.http.post(`${this.url}/ratings/store_3_tool`, req, this.httpOptions);
  }

  getAllPlanList(req){
    return this.http.post(`${this.url}/plan_list`, req,this.httpOptions);
  }

  saveBillingAddress(req){
    return this.http.post(`${this.url}/add_billing_address`, req, this.httpOptions);
  }
  getUserActivePlan(){
    return this.http.post(`${this.url}/user_active_plan`,  this.httpOptions);
  }
  saveUserCard(req){
    return this.http.post(`${this.url}/add_user_cards`, req, this.httpOptions);
  }
  purchasePlan(req){
    return this.http.post(`${this.url}/user_subscription`, req, this.httpOptions);
  }

  getUserCardList(){
    return this.http.get(`${this.url}/user_card_list`, this.httpOptions);
  }
  getUserBillingAddress(){
    return this.http.post(`${this.url}/user_billing_address`, this.httpOptions);
  }
  
  
}
