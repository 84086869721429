<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">{{category_name}}</li>
                    </ul>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-6 text-right d-flex justify-content-end">
                    <div id="navbar-search" class="search-form serach-section">
                        <input class="form-control" [(ngModel)]="searchText" (keyup)="filter_records()" placeholder="Search Programme" type="text">
                        <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                    </div>
                    <!-- <div class="stat-content">
                        <button type="button" class="btn pbtn">Submit</button>
                 </div>    -->
                </div> <!-- /# column -->

            </div>
            <br />
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <section class="learning-banner learning-banner--sm mb-30px">
                        <div class="container">
                            <div class="learning-banner--sm__content">
                                <h1>{{category_name}}</h1>
                                <h6 *ngIf="isFreeTrial">You have a free trial/gold member plan so you can donwload
                                    sample PDF. please upgrade your plan for donwload main PDF</h6>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
   
        <!-- video section -->
        <!-- <div class="row mt-5">
            <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div class="row clearfix top-xcode">
                    <div class="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 text-center"
                        *ngFor="let video of trainingVideoList; index as i">
                        <div class="card-cd">
                            <div class="Defoult-Card">
                                <img src="./assets/assets/images/trainingtamplate.png"
                                    class="roundright-cd border-round">
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">"Fambiz Training -</h5>
                                    <h3 class="skill-cd">Video"</h3>
                                </div>
                                <div class="sidebar-vedio">

                                    <iframe [src]="getSafeImage(video.video_link)" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
    
                                <a class="play_icon" (click)="setSelectedRow(video)"
                                >
                                <img src="./assets/assets/images/youtube-icon.png">
                            </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 justify-content-center">
                <div class="row clearfix justify-content-center">
                    <div class="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 text-center"
                        *ngFor="let value of trainingCourseList; index as i">
                        <div class="card-cd">
                            <a target="_blank"
                            href="{{value.course_link}}">
                            <div class="Defoult-Card">
                                <img src="./assets/assets/images/trainingtamplate.png"
                                    class="roundright-cd border-round">
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">"Fambiz Training -</h5>
                                    <h3 class="skill-cd">Programme"</h3>
                                </div>
                                <div class="sidebar-cd">
                                    <h2 class="h5-cd">{{value.title}}</h2>
                                </div>
                             </div>
                            <!--./Defoult card-->
                          </a>
                              <!-- download icon -->
                              <a target="_blank" href="{{path + 'download_course/?file_name=' + value.training_doc}}">
                                <img src="./assets/assets/images/Download.png" class="sideright-cd" alt="Download Icon">
                              </a>
                            
                        </div>
                        <!--./card-->
                    </div>

                    <!-- end col -->
                </div>
                <!--./div-->
            </div>
            <!--./col-md-12-->
        </div>
        <!--./row-->
        <div class="Chat_section">
            <form [formGroup]="CreateForm" enctype="multipart/form-data">
                <div class="chat" *ngIf="visible">
                    <div id="chat_fullscreen" class="chat_conversion chat_converse">
                        <span class="chat_msg_item chat_msg_item_admin">
                            What type of training are you looking for?
                        </span>
                    </div>
                    <div class="Chat_field">
                        <button type="submit" class="chatIcon_send" (click)="submit()">
                            <img src="./assets/assets/images/send.png">
                        </button>
                        <!-- <a id="chatIcon_send" class="chatIcon_send"> </a> -->
                        <span class="text-danger" *ngIf="control.message.dirty && control.message.hasError('required')">
                            Message field is requied.
                        </span>
                        <input name="chat_message" formControlName="message" placeholder="Send a message"
                            class="chat_field chat_message" />

                        <!-- <textarea ></textarea> -->
                    </div>
                </div>
            </form>
            <a id="queryIcon" class="chatIcon" (click)="onclick()"> <img
                    src="./assets/assets/images/query-icon.png"></a>
        </div>

        <!-- Modal Training_Video-->
        <div class="modal fade Training_Video" id="Training_Video" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" *ngIf="selectedRow">
                    <div class="modal-body">
                        <button type="button"  class="close"  (click)="closeModel()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item"
                            [src]="getSafeImage(selectedRow.video_link)" id="video"
                                allowscriptaccess="always" allow="autoplay"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end Modal Training_Video -->

    </div>
</div>