import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { GlobalConstants } from '../../common/global-constants';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Picker } from 'emoji-picker-element';

@Component({
  selector: 'app-my-favourite',
  templateUrl: './my-favourite.component.html',
  styleUrls: ['./my-favourite.component.css']
})
export class MyFavouriteComponent implements OnInit {

  addForm: FormGroup;
  group_id: any;
  userProfile: any;
  posts: any = [];

  commentForm: FormGroup;
  commentEdit: FormGroup;
  isEdit: boolean = false;

  modalReference: NgbModalRef;
  closeResult: string;

  postEdit: FormGroup;
  isPostEdit: boolean = false;

  roomDetail: any = [];
  boxDisplay: any;
  isPaginationShow = false;
  page = 1;

  comments: any = [];
  commentPage = 1;
  isCommentPageShow = false;

  isScrollStop = false;

  //free trial
  isFreeTrial: boolean = false;
  nextBillingDate = new Date();
  CurrentPlan: any;
  planData: any;
  CurrentPlanID = 0;
  picker = new Picker();
  showEmojiPicker = false;
  showCommentEmojiPicker = false;
  searchText: string;
  serchData = [];
  constructor(private formBuilder: FormBuilder,
    private roomService: DealroomService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        if (res.current_active_plan.plan_id == 1) {
          this.isFreeTrial = true
        }
        //console.log(res,'response --------');
      } else {

      }
    })
    this.getAllPlanList()
    this.getCurrentPlan()
   
  }

  ngOnInit(): void {
    this.group_id = this.route.snapshot.params['id'];

    if (this.authService.user.profile_pic) {
      let path = GlobalConstants.user_profile_path;
      this.userProfile = path + this.authService.user.profile_pic;
    } else {
      this.userProfile = "./assets/assets/images/user.png";
    }

    this.roomService.getSingle(this.group_id).subscribe((res: any) => {
      if (res.status) {
        this.roomDetail = res.dealroom;
      } else {
        this.toastr.error("Room not found", "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })

    this.getAllPost(1);

    this.addForm = this.formBuilder.group({
      post_description: ['', [Validators.required]],
      group_id: ['',],
      post_image: ['',],
      post_video: ['',],
      post_document: ['',],
    })

    this.commentForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      post_id: ['',],
      post_image: ['',],
      /* post_video: ['', ], */
    })

    this.control.group_id.setValue(this.group_id);
    //this.getALLPostSearch();
  }

  filter_records() {
    //console.log("hii")
    this.getAllPostBySearch();
  }
  getCurrentPlan() {
    this.authService.getUserActivePlan().subscribe((res: any) => {
      if (res.status) {
        // console.log(res,'current plan ')
        this.CurrentPlanID = res.current_active_plan.plan_id
        this.CurrentPlan = res.current_active_plan
        this.nextBillingDate = new Date();
        if (this.CurrentPlan.plan.duration_type == 'Days') {
          this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration);
        } else if (this.CurrentPlan.plan.duration_type == 'Years') {
          this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration);
        }
        //console.log(this.CurrentPlan)
        // this.router.navigateByUrl('dashboard');
      } else {
       // this.router.navigateByUrl('OurPlan');

        //alert(res.message);
      }

    }, err => {
      //this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList() {
    this.authService.getAllPlanList({}).subscribe((res: any) => {
      if (res.status) {
        this.planData = res.plans;
        //console.log(this.planData)
      }
    })
  }

  onScroll(e) {
    if (this.isScrollStop) {
      return;
    } else {
      this.getAllPost(this.page);
    }
  }

  visibleIndex = -1;
  showSubItem(ind, post) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
      this.isCommentPageShow = true;
    }
    this.getCommentByPost(post.id, 1, post.comment_count);
    this.comments = [];
    this.commentPage = 1;
  }

  getAllPost(page = 1) {
    this.roomService.getPostByRoomId(this.group_id, page).subscribe((res: any) => {
      if (res.status) {
        if (this.posts.length > 0) {
          this.posts = [...this.posts, ...res.posts];
          //res.posts.forEach(item => this.posts.push(item));
        } else {
          this.posts = res.posts;
        }
        this.boxDisplay = this.posts.map(s => false);
        this.isPaginationShow = true;
        this.page = page + 1;
      } else {
        this.isPaginationShow = false;
        this.isScrollStop = true;
        //this.toastr.error("Post not found", "Error");
      }
    }, err => {
      this.isPaginationShow = false;
      this.isScrollStop = true;
      //this.toastr.error(err, "Error");
    })
  }
  getAllPostBySearch() {
    if (this.searchText == null || this.searchText == '') {
      this.posts = []
      this.getAllPost(1);
    } else {
      const postData = this.serchData.filter(item => item.post_description.toLowerCase().includes(this.searchText.toLowerCase()));
      this.posts = postData;
      this.boxDisplay = this.posts.map(s => false);

    }

  }
  showAllPost(){
    this.getALLPostSearch();
  }
  getALLPostSearch() {
    this.roomService.getAllPostByRoomId(this.group_id).subscribe((res: any) => {
      if (res.status) {
        this.serchData = res.posts;
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }
  getCommentByPost(postId, pages = 1, totalComment) {
    this.roomService.getCommentByPost(postId, pages).subscribe((res: any) => {
      if (res.status) {
        if (this.comments.length > 0) {
          res.data.forEach(item => this.comments.push(item));

          if (totalComment <= this.comments.length) {
            this.isCommentPageShow = false;
          } else {
            this.isCommentPageShow = true;
          }
        } else {
          this.comments = res.data;
          if (totalComment <= this.comments.length) {
            this.isCommentPageShow = false;
          } else {
            this.isCommentPageShow = true;
          }
        }
        //this.isCommentPageShow = true;
        this.commentPage = this.commentPage + 1;
      } else {
        this.isCommentPageShow = false;
        this.toastr.error("Post not found", "Error");
      }
    }, err => {
      this.isCommentPageShow = false;
      this.toastr.error(err, "Error");
    })
  }

  get control() {
    return this.addForm.controls;
  }

  get CControl() {
    return this.commentForm.controls;
  }

  get CEditControl() {
    return this.commentEdit.controls;
  }

  get PEditControl() {
    return this.postEdit.controls;
  }

  submit() {
    if (this.addForm.invalid) {
      this.toastr.error('Please fill out the required fields.', 'Error');
      return;
    }
    this.createUser();
  }

  SubmitComment(postId) {
    if (this.commentForm.invalid) {
      return;
    }
    this.CControl.post_id.setValue(postId);
    const formData = new FormData();
    formData.append('post_image', this.commentForm.get('post_image').value);
    formData.append('description', this.commentForm.get('description').value);
    formData.append('post_id', this.commentForm.get('post_id').value);
    this.roomService.addPostComment(formData).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          if (res.data.comment_count > 1 && this.comments.length > 0) {
            this.comments.unshift(res.data);
          }
          this.isCommentPageShow = true;
          //this.getAllPost();
          this.commentForm.reset();
          this.isCommentImageAdd = false;
          let itemIndex = this.posts.findIndex(item => item.id == postId);
          this.posts[itemIndex].comment_count = res.data.comment_count;
        }
        this.toastr.success('Comment added successfully', "Success");
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }

  UserLike(postId, isLike, isPostOrComment, commentPostId = 0) {
    this.roomService.addPostLike({ post_id: postId, isLike: isLike, isPostOrComment: isPostOrComment, commentPostId: commentPostId }).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          /*if(commentPostId!=0){
            let itemIndex = this.posts.findIndex(item => item.id == commentPostId);
            this.posts[itemIndex] = res.data;
          }else{
            let itemIndex = this.posts.findIndex(item => item.id == postId);
            this.posts[itemIndex] = res.data;
          }*/
          if (isPostOrComment == 'comment') {
            let itemIndex = this.comments.findIndex(item => item.id == postId);
            this.comments[itemIndex] = res.data;
          } else {
            let itemIndex = this.posts.findIndex(item => item.id == postId);
            this.posts[itemIndex] = res.data;
          }
        }
        /*if(isLike){
          this.toastr.success('Unlike successfully', "Success");
        }else{
          this.toastr.success('Like added successfully', "Success");
        }*/
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }

  singleImage: any;
  //commentedPostId:any = 0;
  EditComment(comment, modalId, postId) {
    this.isEdit = true;
    this.commentEdit = this.formBuilder.group({
      description: ['', [Validators.required]],
      comment_id: ['',],
      post_image: ['',],
      delete_image: ['',],
      /* post_video: ['', ], */
    })
    let commentData = [];
    commentData['description'] = comment.description.replace(/<[^>]*>/g, '');
    commentData['comment_id'] = comment.id;
    //commentData['post_image'] = comment.post_image;
    this.commentEdit.patchValue(commentData)
    if (comment.post_image) {
      this.singleImage = comment.post_image;
    } else {
      this.singleImage = '';
    }
    //this.commentedPostId = postId;
    //this.commentedPostId = comment.id;

    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  CommentUpdate(postId) {
    if (this.commentEdit.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('post_image', this.commentEdit.get('post_image').value);
    formData.append('description', this.commentEdit.get('description').value);
    formData.append('comment_id', this.commentEdit.get('comment_id').value);
    formData.append('delete_image', this.commentEdit.get('delete_image').value);
    let commentId = this.commentEdit.get('comment_id').value;
    this.roomService.UpdatePostComment(formData).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          let itemIndex = this.comments.findIndex(item => item.id == commentId);
          this.comments[itemIndex] = res.data;
          //this.getCommentByPost(this.commentedPostId, this.commentPage-1)
          //this.getAllPost();
          this.JoinAndClose();
          this.commentEdit.reset();
          this.isCommentImageEdit = false;
          /*let itemIndex = this.posts.findIndex(item => item.id == commentPostId);
          this.posts[itemIndex] = res.data;*/
        }
        this.toastr.success('Comment updated successfully', "Success");
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }

  DeleteComment(comment) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/delete-icon.png" alt="delete-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.roomService.DeleteComment(comment.id).subscribe((data: any) => {
          if (data.status) {
            this.toastr.success("Comment Deleted Successfully", "Success");
            //this.getAllPost();
            //this.isCommentPageShow = true;
            //this.commentPage = 1;
            this.comments = this.comments.filter(item => item.id !== comment.id);
            let itemIndex = this.posts.findIndex(item => item.id == comment.post_id);
            this.posts[itemIndex].comment_count = this.posts[itemIndex].comment_count - 1;
          } else {
            this.toastr.error(data.message, "Error");
          }
        });
      }
    })
  }

  DeletePost(post) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      // icon: 'warning',
      iconHtml: ' <img src="./assets/assets/images/delete-icon.png" alt="delete-icon">',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.roomService.DeletePost(post.id).subscribe((data: any) => {
          if (data.status) {
            this.toastr.success("Post Deleted Successfully", "Success");
            //this.getAllPost();
            this.posts = this.posts.filter(item => item.id !== post.id);
          } else {
            this.toastr.error(data.message, "Error");
          }
        });
      }
    })
  }

  postImage: any;
  postVideo: any;
  postDocument: any;
  postDocumentName: any;

  EditPost(post, modalId) {
    this.isPostEdit = true;
    this.postEdit = this.formBuilder.group({
      post_description: ['', [Validators.required]],
      post_id: ['',],
      post_image: ['',],
      post_video: ['',],
      post_document: ['',],
      delete_image: ['',],
      delete_video: ['',],
      delete_document: ['',],
    })
    let postData = [];
    postData['post_description'] = post.post_description.replace(/<[^>]*>/g, '');
    postData['post_id'] = post.id;
    postData['delete_image'] = false;
    postData['delete_video'] = false;
    postData['delete_document'] = false;

    //postData['post_image'] = comment.post_image;
    this.postEdit.patchValue(postData)
    if (post.post_image) {
      this.postImage = post.post_image;
    } else {
      this.postImage = '';
    }

    if (post.post_video) {
      this.postVideo = post.post_video;
    } else {
      this.postVideo = '';
    }

    if (post.post_document) {
      this.postDocument = post.post_document;
      this.postDocumentName = post.document_name;
    } else {
      this.postDocument = '';
    }



    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  OpenPlanPopup(modalId) {
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  Update() {
    if (this.postEdit.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('post_id', this.postEdit.get('post_id').value);
    formData.append('post_description', this.postEdit.get('post_description').value);
    formData.append('post_image', this.postEdit.get('post_image').value);
    formData.append('post_video', this.postEdit.get('post_video').value);
    formData.append('delete_image', this.postEdit.get('delete_image').value);
    formData.append('delete_video', this.postEdit.get('delete_video').value);
    formData.append('post_document', this.postEdit.get('post_document').value);
    formData.append('delete_document', this.postEdit.get('delete_document').value);

    let postId = this.postEdit.get('post_id').value;
    this.roomService.UpdatePost(formData).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          //this.posts.push(res.data)
          //this.posts.unshift(res.data)
          //this.getAllPost();
          let itemIndex = this.posts.findIndex(item => item.id == postId);
          this.posts[itemIndex] = res.data;
          this.JoinAndClose();
          this.postEdit.reset();
          this.isPostDocEdit = false;
          this.isPostImageEdit = false;
          this.isPostVideoEdit = false;
        }
        this.toastr.success('Post updated successfully', "Success");
        //this.router.navigateByUrl('admin/dealroom');
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }

  UserFollow(postId, isFollow, isPostOrComment, commentPostId = 0) {
    this.roomService.addPostFollow({ post_id: postId, isFollow: isFollow }).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          let itemIndex = this.posts.findIndex(item => item.id == postId);
          this.posts[itemIndex] = res.data;
        }
        /*if(isFollow){
          this.toastr.success('Unfollow successfully', "Success");
        }else{
          this.toastr.success('Follow added successfully', "Success");
        }*/
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

  JoinAndClose() {
    this.modalReference.close();
    //this.commentedPostId = 0;
  }

  isPostImageAdd = false;
  isPostImageEdit = false;
  postImgURL: any;
  onFileChange(event, isEdit = false) {
    if (event.target.files && event.target.files.length > 0) {

      const file = event.target.files[0];
      const fileSizeInKB = Math.round(file.size / 1024);
      if (fileSizeInKB >= 5012) {
        this.toastr.error("Allow only 5 mb image size", "Error");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (_event) => {
        this.postImgURL = reader.result;
      }
      if (isEdit) {
        this.isPostImageEdit = true;
        this.PEditControl.post_image.setValue(file);
      } else {
        this.isPostImageAdd = true;
        this.control.post_image.setValue(file);

      }
    } else {
      this.postImgURL = "";
      this.isPostImageAdd = false;
    }
  }

  isPostVideoAdd = false;
  isPostVideoEdit = false;
  postVideoURL: any;
  onVideoChange(event, isEdit = false) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSizeInKB = Math.round(files.size / 1024);
      if (fileSizeInKB >= 50000) {
        this.toastr.error("Allow only 50 mb video size", "Error");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (_event) => {
        this.postVideoURL = reader.result;
      }
      if (isEdit) {
        this.isPostVideoEdit = true;
        this.PEditControl.post_video.setValue(files);
      } else {
        this.isPostVideoAdd = true;
        this.control.post_video.setValue(files);
      }
      //this.control.post_video.setValue(files);
    } else {
      this.postVideoURL = "";
      this.isPostVideoAdd = false;
    }
  }

  isPostDocAdd = false;
  isPostDocEdit = false;
  postDocURL: any;
  onDocChange(event, isEdit = false) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files[0];
      const fileSizeInKB = Math.round(files.size / 1024);
      if (fileSizeInKB >= 10025) {
        this.toastr.error("Allow only 10 mb document size", "Error");
        return;
      }
      this.postDocURL = "./assets/assets/images/doc_icon.png";
      if (isEdit) {
        this.isPostDocEdit = true;
        this.PEditControl.post_document.setValue(files);
      } else {
        this.isPostDocAdd = true;
        this.control.post_document.setValue(files);
      }
    } else {
      this.postDocURL = "";
      this.isPostDocAdd = false
    }
  }

  private createUser() {
    console.log("Form submitted successfully with description:", this.addForm.get('post_description').value);
    const postDescription = this.addForm.get('post_description').value;

    const formData = new FormData();
    formData.append('post_image', this.addForm.get('post_image').value);
    formData.append('group_id', this.group_id);
    formData.append('post_description', this.addForm.get('post_description').value);
    formData.append('post_video', this.addForm.get('post_video').value);
    formData.append('post_document', this.addForm.get('post_document').value);

    this.roomService.addPost(formData).subscribe((res: any) => {
      if (res.status) {
        if (res.data) {
          this.posts.unshift(res.data)
          //this.getAllPost(1);
          this.addForm.reset();
          this.isPostDocAdd = false;
          this.isPostImageAdd = false;
          this.isPostVideoAdd = false;
          this.postImgURL = "";
          this.postVideoURL = "";
          this.postDocURL = "";
        }
        this.toastr.success('Post added successfully', "Success");
        
        //this.router.navigateByUrl('admin/dealroom');
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err => {
      this.toastr.error(err, "Error");
    })

  }

  DeleteImage(type = 'image', posttype = 'post') {
    if (posttype == 'post') {
      if (type == 'image') {
        this.PEditControl.delete_image.setValue(true);
        this.postImage = '';
      }
      if (type == 'document') {
        this.PEditControl.delete_document.setValue(true);
        this.postDocument = '';
        this.postDocumentName = '';
      }

      if (type == 'video') {
        this.PEditControl.delete_video.setValue(true);
        this.postVideo = '';
      }
    } else {
      //comment form
      this.CEditControl.delete_image.setValue(true);
      this.singleImage = '';
    }
  }

  isCommentImageAdd = false;
  isCommentImageEdit = false;
  onCommentImage(event, isEditComment = false) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (isEditComment) {
        this.isCommentImageEdit = true;
        this.CEditControl.post_image.setValue(file);
      } else {
        this.isCommentImageAdd = true;
        this.CControl.post_image.setValue(file);
      }
    }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    if (event?.detail?.unicode) {
      let value = this.addForm.controls['post_description'].value + event?.detail?.unicode;
      this.addForm.controls['post_description'].setValue(value);
    }
  }

  toggleCommentEmojiPicker() {
    this.showCommentEmojiPicker = !this.showCommentEmojiPicker;
  }

  addCommentEmoji(event: any) {
    if (event?.detail?.unicode) {
      let value = this.commentForm.controls['description'].value + event?.detail?.unicode;
      this.commentForm.controls['description'].setValue(value);
    }
  }
}
